import React from "react"

const Brand = () => {
  return (
    <div className="bg-white py-24 sm:py-32" id="zakres">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900">
            Czym się zajmujemy?
          </h2>
        </div>
        <div className="mx-auto mt-10 max-w-3xl sm:mt-16 lg:mt-20 lg:max-w-5xl">
          <dl className="mx-auto grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
            <div className="relative pl-12">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute top-0 left-0 flex h-8 w-8 items-center justify-center rounded-lg bg-[#009651]">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    stroke="currentColor"
                    fill="currentColor"
                  >
                    <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"
                    />
                  </svg>
                </div>
                Płatności bezpośrednie
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Pomoc w wypełnieniu oraz złożeniu wniosku.
              </dd>
            </div>

            <div className="relative pl-12">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute top-0 left-0 flex h-8 w-8 items-center justify-center rounded-lg bg-[#009651]">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    stroke="currentColor"
                    fill="currentColor"
                  >
                    <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"
                    />
                  </svg>
                </div>
                Ekoschematy
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Pomoc w wybraniu najbardziej korzystnych ekoschematów
                dopasowanych do możliwości gospodarstwa.
              </dd>
            </div>

            <div className="relative pl-12">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute top-0 left-0 flex h-8 w-8 items-center justify-center rounded-lg bg-[#009651]">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    stroke="currentColor"
                    fill="currentColor"
                  >
                    <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"
                    />
                  </svg>
                </div>
                Rozwój Małych Gospodarstw
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Opracowanie biznesplanu gospodarstwa oraz wniosku a także
                przeprowadzenie przez cały okres realizacji inwestycji.
              </dd>
            </div>
            <div className="relative pl-12">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute top-0 left-0 flex h-8 w-8 items-center justify-center rounded-lg bg-[#009651]">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    stroke="currentColor"
                    fill="currentColor"
                  >
                    <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"
                    />
                  </svg>
                </div>
                Premie dla Młodych Rolników
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Opracowanie biznesplanu gospodarstwa oraz wniosku a także
                przeprowadzenie przez cały okres realizacji inwestycji.
              </dd>
            </div>
            <div className="relative pl-12">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute top-0 left-0 flex h-8 w-8 items-center justify-center rounded-lg bg-[#009651]">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    stroke="currentColor"
                    fill="currentColor"
                  >
                    <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"
                    />
                  </svg>
                </div>
                Plany nawozowe / nawożenia azotem
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Sporządzenie planu nawozowego oraz obliczenie maksymalnych dawek
                azotu co pozwoli obniżyć koszty nawożenia.
              </dd>
            </div>
            <div className="relative pl-12">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute top-0 left-0 flex h-8 w-8 items-center justify-center rounded-lg bg-[#009651]">
                  <svg
                    className="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    stroke="currentColor"
                    fill="currentColor"
                  >
                    <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"
                    />
                  </svg>
                </div>
                Rozliczanie VAT
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Usługi księgowe w zakresie rozliczenia podatku VAT dla
                gospodarstw rolnych.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Brand
