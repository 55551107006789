import React, { useRef } from "react"
import emailjs from "@emailjs/browser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faEnvelope,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Contact = () => {
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        "service_oop2e19",
        "template_j8budve",
        form.current,
        "_9vgR8tEHYkN2i8Go"
      )
      .then(
        () => {
          e.target.reset()
          toast.success("Twoja wiadomość została wysłana!")
        },
        () => {
          toast.error("Wystąpił błąd, spróbuj ponownie.")
        }
      )
  }

  return (
    <>
      <div className="bg-gray-50 py-24 sm:py-32" id="kontakt">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid grid-cols-1 gap-y-14 md:grid-cols-2 mt-2 max-w-4xl lg:max-w-5xl">
            <div className="flex flex-col h-full justify-between">
              <h2
                className={`font-['Aleo'] text-gray-900  font-bold text-[40px] md:text-[60px] sm:mb-2 lg:leading-[98px] mt-2`}
              >
                Kontakt
              </h2>

              <div className="flex mb-14">
                <div className="p-4 bg-[#009651] rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                  <FontAwesomeIcon icon={faPhone} />
                </div>

                <div className="flex justify-center items-center ml-6">
                  <p className="font-bold text-lg sm:text-3xl">785-461-977</p>
                </div>
              </div>
              <div className="flex flex-wrap mb-14">
                <div className="p-4 bg-[#009651] rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>

                <div className="flex justify-center items-center ml-6">
                  <p className="font-bold text-sm sm:text-xl break-normal">
                    mazurek@agrownioski.pl
                  </p>
                </div>
              </div>
              <div className="flex mb-14">
                <div className="p-4 bg-[#009651] rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                  <FontAwesomeIcon icon={faLocationPin} />
                </div>

                <div className="flex flex-col  ml-6">
                  <p className="font-bold text-md sm:text-xl break-words">
                    22-600 Tomaszów Lubelski
                  </p>
                  <p className="font-semibold text-md sm:text-xl break-words">
                    Rolnicza 15a (1 piętro)
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white p-5 rounded-md shadow-lg">
              <form ref={form} onSubmit={sendEmail}>
                <div className="grid gap-6 mb-6">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-lg font-medium text-gray-900"
                    >
                      Imię i nazwisko
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      name="from_name"
                      className="bg-gray-50 border border-[#009651] text-gray-900 text-sm rounded-lg focus:ring-[#009651] focus:border-[#009651] block w-full p-2.5"
                      placeholder="Jan Kowalski"
                      required
                    ></input>
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="block mb-2 text-lg font-medium text-gray-900"
                    >
                      Numer telefonu
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="telefon"
                      className="bg-gray-50 border border-[#009651] text-gray-900 text-sm rounded-lg focus:ring-[#009651] focus:border-[#009651] block w-full p-2.5"
                      placeholder="123456789"
                      pattern="[0-9]{3}[0-9]{3}[0-9]{3}"
                      required
                    ></input>
                  </div>
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-lg font-medium text-gray-900e"
                  >
                    Adres email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="bg-gray-50 border border-[#009651] text-gray-900 text-sm rounded-lg focus:ring-[#009651] focus:border-[#009651] block w-full p-2.5"
                    placeholder="jan.kowalski@gmail.com"
                  ></input>
                </div>

                <label
                  htmlFor="message"
                  className="block mb-2 text-lg font-medium text-gray-900"
                >
                  Wiadomość
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="bg-gray-50 border border-[#009651] text-gray-900 text-sm rounded-lg focus:ring-[#009651] focus:border-[#009651] block w-full p-2.5"
                  placeholder="Wiadomość..."
                ></textarea>

                <button
                  type="submit"
                  className="text-white bg-[#009651] hover:bg-[#026635] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  mt-2 px-5 py-2.5 text-center"
                >
                  Wyślij
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
