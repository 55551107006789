import mlodyRolnik from "../images/farmer.jpg"
import RMG from "../images/farm.jpg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSackDollar, faSquareCheck } from "@fortawesome/free-solid-svg-icons"
const Nabory = () => {
  return (
    <div className="mx-auto max-w-none " id="nabory">
      <div className="relative isolate overflow-hidden bg-gray-900 px-6 pb-5 pt-16 shadow-2xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 xl:gap-x-44 lg:px-24 lg:pt-0">
        <svg
          viewBox="0 0 1024 1024"
          className="absolute top-1/3 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:translate-y-0 lg:-translate-x-1/2"
          aria-hidden="true"
        >
          <circle
            cx={512}
            cy={512}
            r={512}
            fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
            fillOpacity="0.7"
          />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stopColor="rgb(0 150 81)" />
              <stop offset={1} stopColor="rgb(0 150 81)" />
            </radialGradient>
          </defs>
        </svg>
        <div className="mx-auto max-w-2xl text-center lg:mx-0 xl:ml-44 lg:flex-auto lg:py-8 lg:text-left">
          <p className="text-base font-semibold tracking-tight text-green-600 sm:text-md">
            Termin naboru wniosków: 30.06.2023 - 31.07.2023
          </p>
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl pt-4">
            Premie dla młodych rolników
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300 font-bold">
            <FontAwesomeIcon
              icon={faSackDollar}
              shake
              size="lg"
              className="text-green-500"
            />{" "}
            200 000 złotych bezzwrotnej premii dla młodych rolników
            rozpoczynających swoją działalność rolniczą
          </p>
          <hr className="my-2 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
          <h3 className="text-xl font-bold tracking-tight text-white sm:text-2xl text-center ">
            Warunki
          </h3>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            posiadanie kwalifikacji zawodowych lub deklaracja ich uzupełnienia
          </p>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            wielkość ekonomiczna w okresie wyjściowym minimum 15 000 euro i nie
            więcej niż 150 000 euro
          </p>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            powierzchnia użytków rolnych nie przekracza 300 ha
          </p>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            w ramach premii możliwy będzie zakup użytków rolnych oraz nowych
            maszyn i urządzeń
          </p>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            inwestycje budowlane tylko na gruntach stanowiących własność
          </p>

          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
            <a
              href="/"
              download
              className="rounded-md bg-[#007F42] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#009651] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Pobierz dokumenty
            </a>
            <a
              href="#kontakt"
              className="text-sm font-semibold leading-6 text-white"
            >
              Dowiedz się więcej <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>

        <div className="relative mt-16 h-80 lg:mt-8 ">
          <img
            className="absolute top-0 left-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10 object-top"
            src={mlodyRolnik}
            alt="App screenshot"
            width={1824}
            height={1080}
          />
        </div>
      </div>

      <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 xl:gap-x-44 lg:px-24 lg:pt-0">
        <svg
          viewBox="0 0 1024 1024"
          className="absolute bottom-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:translate-y-0 lg:-translate-x-1/2"
          aria-hidden="true"
        >
          <circle
            cx={512}
            cy={512}
            r={512}
            fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
            fillOpacity="0.7"
          />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stopColor="rgb(0 150 81)" />
              <stop offset={1} stopColor="rgb(0 150 81)" />
            </radialGradient>
          </defs>
        </svg>
        <div className="mx-auto max-w-2xl text-center lg:mx-0 xl:ml-44 lg:flex-auto lg:py-8 lg:text-left">
          <p className="text-base font-semibold tracking-tight text-green-600 sm:text-md">
            Termin naboru wniosków: 01.08.2023 - 31.08.2023
          </p>
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl pt-4">
            Rozwój małych gospodarstw
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300 font-bold">
            <FontAwesomeIcon
              icon={faSackDollar}
              shake
              size="lg"
              className="text-green-500"
            />{" "}
            120 000 zł - gospodarstwa rozpoczynające działalność w zakresie
            wprowadzania produktów na rynek w ramach tzw. krótkiego łańcucha
            dostaw (RHD, sprzedaż bezpośrednia, dostawy bezpośrednie) oraz
            gospodarstwa prowadzące produkcję ekologiczną
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300 font-bold">
            <FontAwesomeIcon
              icon={faSackDollar}
              shake
              size="lg"
              className="text-green-500"
            />{" "}
            100 000 zł - pozostałe gospodarstwa
          </p>
          <hr className="my-2 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
          <h3 className="text-xl font-bold tracking-tight text-white sm:text-2xl text-center ">
            Warunki
          </h3>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            wymagany wkład własny na poziomie 15% kosztów inwestycji
          </p>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            wielkość ekonomiczna gospodarstwa poniżej 25 000 euro
          </p>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            udokumentowany przychód na co najmniej 5 000 zł z działalności
            rolniczej
          </p>
          <hr className="my-2 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
          <h3 className="text-xl font-bold tracking-tight text-white sm:text-2xl text-center ">
            Środki można przeznaczyć na:
          </h3>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            zakup nowych maszyn i urządzeń
          </p>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            inwestycje budowlane, remonty, modernizacje budynków oraz ich
            wyposażenie
          </p>
          <p className="mt-3 text-sm leading-8 text-gray-300 font-semibold">
            <FontAwesomeIcon
              icon={faSquareCheck}
              beat
              className="mr-2 text-green-500"
            />
            wartości niematerialne i prawne
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
            <a
              href="/"
              className="rounded-md bg-[#007F42] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#009651] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Pobierz dokumenty
            </a>
            <a
              href="#kontakt"
              className="text-sm font-semibold leading-6 text-white"
            >
              Dowiedz się więcej <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
        <div className="relative mt-16 h-80 lg:mt-8 ">
          <img
            className="absolute top-0 left-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10 object-top"
            src={RMG}
            alt="App screenshot"
            width={1824}
            height={1080}
          />
        </div>
      </div>
    </div>
  )
}

export default Nabory
