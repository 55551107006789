import React from "react"
import logo from "../images/logo-01.png"

const Footer = () => {
  return (
    <footer className="p-2 bg-gray-800 rounded-t-lg shadow md:px-2 md:py-2 h-16">
      <div className="flex items-center justify-between">
        <a href="https://agrownioski.pl/">
          <img src={logo} className="h-14" alt="Agrownioski Logo" />
        </a>
        <span className="text-xs text-gray-400 sm:text-center">
          © Rafał Szostak
        </span>
      </div>
    </footer>
  )
}

export default Footer
