import React from "react"

const Feature = () => {
  return (
    <div className="bg-gray-50 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900">
            Jak to działa?
          </h2>
          <p className="max-w-3xl mt-3 text-xl  leading-8 text-gray-600">
            Przemyślane etapy pracy nad danym wnioskiem zapewniają płynną i
            przejrzystą współpracę. Otrzymasz listę dokumentów jakie należy
            dotarczyć, aby opracowanie biznesplanu i wniosku odbyło się z
            najwyższą starannością.
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-3xl sm:mt-16 lg:mt-20 lg:max-w-5xl">
          <dl className="mx-auto grid max-w-xl grid-cols-1 gap-y-10 gap-x-8  lg:grid-cols-3 lg:max-w-none">
            <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute top-0 left-0 text-4xl font-light">
                  01
                </div>
                Profesjonalne doradztwo
              </dt>
              <dd className="mt-2 text-base leading-7 font-medium text-gray-600">
                Wiedza i doświadczenie pozwolą wybrać najkorzystniejsze formy
                wsparcia dla Twojego gospodarstwa.
              </dd>
            </div>

            <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute top-0 left-0 text-4xl font-light">
                  02
                </div>
                Szybka realizacja
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Mając na uwadze fakt, iż nabory mają określone terminy, po
                dostarczeniu kompletu dokumentów Twój wniosek już będzie w
                opracowaniu!
              </dd>
            </div>

            <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute top-0 left-0 text-4xl font-light">
                  03
                </div>
                Kompleksowe usługi
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                Płatności bezpośrednie, Rozwój Małych Gospodarstw, Premie dla
                Młodych Rolników, a może rozliczenie VAT? Nie czekaj, zadzwoń!
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Feature
