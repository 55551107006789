import Brand from "./components/Brand"
import Contact from "./components/Contact"
import Feature from "./components/Feature"
import Navbar from "./components/Navbar"
import Header from "./components/Header"
import Footer from "./components/Footer"
import { ToastContainer } from "react-toastify"
import Nabory from "./components/Nabory"

function App() {
  return (
    <>
      <div className="App">
        <Navbar />
        <Header />
        <Feature />
        <Nabory />
        <Brand />
        <Contact />
        <Footer />
      </div>
      <ToastContainer />
    </>
  )
}

export default App
