import React from "react"

const Header = () => {
  return (
    <div className="relative w-full h-[700px] mx-auto ">
      <div className="absolute inset-0 top-16 bg-cover bg-no-repeat bg-center bg-hero flex sm:px-16 px-6">
        <div className="flex flex-col justify-center items-center mt-5">
          <div>
            <h1
              className={`font-['Aleo'] text-white  font-bold lg:text-[80px] sm:text-[60px] xs:text-[50px] text-[36px] lg:leading-[98px] mt-2`}
            >
              AGROWNIOSKI
            </h1>
            <p
              className={`text-white font-bold lg:text-[30px] sm:text-[26px] xs:text-[20px] text-[16px] lg:leading-[40px] mt-2 `}
            >
              Profesjonalne doradztwo <br className="block xl:hidden" />w
              zakresie wsparcia rolnictwa
            </p>
            <div className="mt-10 ml-2 flex items-center">
              <a
                href="#kontakt"
                className="uppercase rounded-md bg-[#007F42] px-8 py-4 lg:text-[18px] sm:text-[16px] xs:text-[14px] text-[12px] font-semibold text-white shadow-sm hover:bg-[#009651] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#009651]"
              >
                SKONTAKTUJ SIĘ
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
