import React, { useState } from "react"
import logo from "../images/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons"
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <nav className="w-full fixed top-0 z-20 bg-[#323F4A]">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 shadow-md">
        <div className=" flex justify-center sm:justify-between items-center h-16 ">
          <div className="flex items-center">
            <img src={logo} alt="logo" className="h-10 w-auto" />
            <span className="font-['Aleo'] text-white font-bold text-lg ml-3">
              AGROWNIOSKI
            </span>
          </div>
          <div className="hidden sm:ml-6 sm:block">
            <div className="flex space-x-4 uppercase">
              <a href="#nabory">
                <span className="text-white px-3 py-2 text-sm font-bold">
                  Aktualne nabory
                </span>
              </a>
              <a href="#zakres">
                <span className="text-white px-3 py-2 text-sm font-bold">
                  Czym się zajmujemy?
                </span>
              </a>
              <a href="#kontakt">
                <span className="text-white px-3 py-2 text-sm font-bold">
                  Kontakt
                </span>
              </a>
            </div>
          </div>
          <div className="absolute inset-y-5 right-2 sm:hidden">
            <button
              type="button"
              className=" text-white"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                <FontAwesomeIcon icon={faXmark} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="sm:hidden">
          <div className="space-y-1 px-2 pt-2 pb-3">
            <a
              href="#nabory"
              className="text-gray-300  hover:bg-gray-700 block rounded-md px-3 py-2 text-base font-medium"
            >
              Aktualne nabory
            </a>
            <a
              href="#zakres"
              className="text-gray-300  hover:bg-gray-700 block rounded-md px-3 py-2 text-base font-medium"
            >
              Czym się zajmujemy?
            </a>

            <a
              href="#kontakt"
              className="text-gray-300 hover:bg-gray-700 block rounded-md px-3 py-2 text-base font-medium"
            >
              Kontakt
            </a>
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navbar
